import omit from 'lodash/omit';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { fetchSiteSettings, showDialog } from '../../core/actions';
import Button from '../../core/components/Button';
import FocusMenu from '../../core/components/FocusMenu';
import { hasRole, RoleTypes } from '../../core/components/Permission';
import Tabs from '../../core/components/Tabs';
import UserGroupPermissions, {
  viewPermissionRefs,
} from '../../core/components/UserGroupPermissions';
import {
  selectPermissions,
  selectRole,
  selectSite,
  selectUserGroup,
} from '../../store/authReducer';
import { getDamageClaimOptions, updateDamageClaimOptions } from '../reclamations/actions';
import { selectReclamationDamageOptions } from '../reclamations/reducer';
import * as actions from './actions';
import ManageUsers from './components/ManageUsers';
import Products from './components/Products';
import UpdateSettingsForm from './components/UpdateSettingsForm';
import {
  selectSiteModelYears,
  selectSiteOperationTypes,
  selectSiteSettings,
  selectWashCounterEnabled,
  selectSiteInteriorDetailRadius,
  selectDateRangeLimiterEnabled,
  selectDateRangeLimiter,
  selectSiteInstallationGroup,
} from './reducer';
import EssentialTasks from './components/EssentialTasks';
import EssentialParts from './components/EssentialParts';
import { Card } from 'reactstrap';
import { getApiStatus } from '../../core/utils';
import Loader from '../../core/components/Loader';
import { counterFormType } from './models';
import { fetchSiteInstallationGroup } from './actions';
import { fetchProducts } from '../products/actions';

const styles = {
  main: {
    height: '100%',
    borderRadius: '3px',
  },
  settingsContainer: {
    marginBottom: '50px',
  },
};

export const Settings = () => {
  // subscribe to the store
  const primarySiteId = useSelector(selectSite);
  const siteSettings = useSelector(selectSiteSettings);
  const interiorDetailRadius = useSelector(selectSiteInteriorDetailRadius);
  const dateRangeLimiterEnabled = useSelector(selectDateRangeLimiterEnabled);
  const dateRangeLimiter = useSelector(selectDateRangeLimiter);
  const washCounterEnabled = useSelector(selectWashCounterEnabled);
  const damageOptions = useSelector(selectReclamationDamageOptions);
  const operations = useSelector(selectSiteOperationTypes);
  const siteModelYearList = useSelector(selectSiteModelYears);
  const userGroup = useSelector(selectUserGroup);
  const permissions = useSelector(selectPermissions);
  const siteOperationTypeList = useSelector(selectSiteOperationTypes);
  const role = useSelector(selectRole);
  const isLoading = useSelector((state) => getApiStatus(state, 'FETCH_SITE_SETTINGS'));
  const isUpdating = useSelector((state) => getApiStatus(state, 'UPDATE_SITE_SETTINGS'));
  const formValues = useSelector(getFormValues('settings'));

  // context
  const dispatch = useDispatch();
  const intl = useIntl();

  // local state
  const [selectedTabType, setSelectedTabType] = useState(viewPermissionRefs.settings);
  const [dateRange, setDateRange] = useState(dateRangeLimiter?.range);
  const [dateType, setDateType] = useState(dateRangeLimiter?.type ?? 'days');

  useEffect(() => {
    dispatch(fetchSiteSettings());
    dispatch(getDamageClaimOptions());
    dispatch(fetchProducts());

    if (hasRole(role, RoleTypes.OperatorGroupAdmin)) {
      if (!siteModelYearList.length) {
        dispatch(actions.getSiteModelYears());
      }

      if (!siteOperationTypeList.length) {
        dispatch(actions.getSiteOperationTypes());
      }
    }
  }, [primarySiteId]);

  useEffect(() => {
    setDateRange(dateRangeLimiter.range);
  }, [dateRangeLimiter]);

  useEffect(() => {
    if (siteSettings.vpnGroupEnabled && !isLoading && !isUpdating) {
      dispatch(fetchSiteInstallationGroup(siteSettings.siteInstallationId));
    }
  }, [isLoading, isUpdating]);

  const handleOnChange = (event) => {
    setDateRange(event.target.value);
  };

  const createDateRangeDropdown = () => {
    const dateTypes = ['days', 'weeks', 'months'];
    return (
      <select
        className="form-group form-control"
        value={dateType}
        onChange={(event) => setDateType(event.target.value)}
        placeholder={dateType}
      >
        {_.map(dateTypes, (type) => (
          <option key={type} value={`${type}`}>
            {`${type.charAt(0).toUpperCase()}${type.slice(1)}`}
          </option>
        ))}
      </select>
    );
  };

  const handleSubmit = () => {
    const settings = {
      ...siteSettings,
      ...formValues,
    };
    if (!formValues.dateRangeLimiterEnabled) {
      settings.dateRangeLimiter = {};
    } else {
      settings.dateRangeLimiter = { range: String(dateRange).toString(), type: dateType };
    }

    const newSettings = {
      settings,
      modelYearId: formValues.modelYearId || null,
      operationTypeId: formValues.operationTypeId || null,
    };

    dispatch(
      actions.updateSiteSettings(
        omit(newSettings, [
          'settings.site',
          'settings.modelYearId',
          'settings.operationTypeId',
          'settings.settings',
        ])
      )
    );
  };

  const getTabsProps = () => ({
    tabs: getTabs(),
    onClick: (type) => {
      setSelectedTabType(type);
    },
    selectedType: selectedTabType,
  });

  const editWashCounter = () => {
    const dialogProps = {
      title: intl.formatMessage({ id: 'configureWashCounter' }),
      operations: operations,
      formType: counterFormType.Exterior,
    };
    dispatch(showDialog('UPSERT_WASH_COUNTER', dialogProps));
  };

  const editInteriorWashCounter = () => {
    const dialogProps = {
      title: intl.formatMessage({ id: 'configureInteriorWashCounter' }),
      operations: operations,
      formType: counterFormType.Interior,
    };
    dispatch(showDialog('UPSERT_WASH_COUNTER', dialogProps));
  };

  const getTabs = () => {
    const { formatMessage } = intl;
    return [
      {
        component: (
          <div className="form-row no-wrap-mobile">
            <Card
              data-testid="siteControlSettings"
              style={{ display: 'flex' }}
              className="enhanced-dialog"
            >
              <h2>{intl.formatMessage({ id: 'siteControlSettings' })}</h2>
              <UserGroupPermissions itemRef={viewPermissionRefs.settings}>
                {siteSettings && (
                  <UpdateSettingsForm
                    onSubmit={handleSubmit}
                    siteModelYears={siteModelYearList}
                    siteOperationTypes={siteOperationTypeList}
                    initialValues={siteSettings}
                    formatMessage={intl.formatMessage}
                    isUpdating={isUpdating}
                    isLoading={isLoading}
                    role={role}
                  />
                )}
              </UserGroupPermissions>
            </Card>
            <div className="enhanced-dialog">
              {washCounterEnabled && (
                <div>
                  <div style={styles.settingsContainer}>
                    <h2>{formatMessage({ id: 'washCounter' })}</h2>
                    <Button data-testid="washCounterButton" onClick={editWashCounter}>
                      {formatMessage({ id: 'configure' })}
                    </Button>
                  </div>
                  <div style={styles.settingsContainer}>
                    {interiorDetailRadius && interiorDetailRadius > 0 ? (
                      <>
                        <h2>{formatMessage({ id: 'interiorWashCounter' })}</h2>
                        <Button
                          data-testid="interiorDetailRadius"
                          onClick={editInteriorWashCounter}
                        >
                          {formatMessage({ id: 'configure' })}
                        </Button>
                      </>
                    ) : null}
                  </div>
                </div>
              )}
              {dateRangeLimiterEnabled && (
                <div>
                  <h2>{formatMessage({ id: 'dateRangeLimiter' })}</h2>
                  <div className="form-row" style={{ display: 'inline-table' }}>
                    {createDateRangeDropdown()}
                    <input
                      className="form-group form-control"
                      placeholder={intl.formatMessage({ id: 'enterANumber' })}
                      type="number"
                      value={dateRange}
                      onChange={handleOnChange}
                    />
                    <label></label>{' '}
                  </div>
                </div>
              )}
            </div>
          </div>
        ),
        title: intl.formatMessage({ id: 'settings' }),
        type: viewPermissionRefs.settings,
        permissionKey: viewPermissionRefs.settings,
      },
      {
        component: (
          <UserGroupPermissions itemRef={viewPermissionRefs.products}>
            <Products operations={operations} />
          </UserGroupPermissions>
        ),
        title: intl.formatMessage({ id: 'products' }),
        type: viewPermissionRefs.products,
        permissionKey: viewPermissionRefs.products,
      },
      {
        component: (
          <UserGroupPermissions itemRef={viewPermissionRefs.damage}>
            <FocusMenu
              data={damageOptions}
              parentLabelKey="value"
              childLabelKey="value"
              childKey="options"
              onSubmit={(output) => {
                updateDamageClaimOptions(output);
              }}
            />
          </UserGroupPermissions>
        ),
        title: intl.formatMessage({ id: 'damageOptions' }),
        type: viewPermissionRefs.damage,
        permissionKey: viewPermissionRefs.damage,
      },
      {
        component: (
          <UserGroupPermissions itemRef={viewPermissionRefs.manageUsers}>
            <EssentialTasks />
          </UserGroupPermissions>
        ),
        title: intl.formatMessage({ id: 'essentialTasks' }),
        type: viewPermissionRefs.admin,
        permissionKey: viewPermissionRefs.admin,
      },
      {
        component: (
          <UserGroupPermissions itemRef={viewPermissionRefs.manageUsers}>
            <ManageUsers />
          </UserGroupPermissions>
        ),
        title: intl.formatMessage({ id: 'manageTeam' }),
        type: viewPermissionRefs.manageUsers,
        permissionKey: viewPermissionRefs.manageUsers,
      },
      {
        component: (
          <UserGroupPermissions itemRef={viewPermissionRefs.manageUsers}>
            <EssentialParts></EssentialParts>
          </UserGroupPermissions>
        ),
        title: intl.formatMessage({ id: 'essentialParts' }),
        type: viewPermissionRefs.essential_parts,
        permissionKey: viewPermissionRefs.essential_parts,
      },
    ];
  };

  const visibleComponent = getTabs().find((t) => t.type === selectedTabType).component;
  const isDisabled = () => {
    if (
      (!formValues?.modelYearId || !formValues?.operationTypeId) &&
      hasRole(role, RoleTypes.OperatorGroupAdmin)
    ) {
      return true;
    }
    return false;
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="container-fluid">
      <Tabs {...getTabsProps()} userGroup={userGroup} permissions={permissions} />
      <section className="page-main-content" style={styles.main}>
        {isLoading ? <Loader /> : visibleComponent}
      </section>
      <section
        className="page-main-content"
        style={{ ...styles.main, display: 'flex', justifyContent: 'right' }}
      >
        <button
          className="button settings-button"
          type="submit"
          data-testid="settingsSubmit"
          disabled={isDisabled()}
          onClick={() => handleSubmit()}
        >
          {intl.formatMessage({ id: 'save' })}
        </button>
      </section>
    </div>
  );
};

export default Settings;
