import moment from 'moment';
import {
  ADD_TASK_COMPLETION,
  API_REQUEST,
  COMPLETE_TASK_COMPLETION,
  COMPLETE_TASK_COMPLETION_AOT,
  SNOOZE_TASK_COMPLETION,
} from '../../core/actions';
import * as alertsAPI from '../../core/api/alerts';
import * as costPerCarAPI from '../../core/api/cost-per-car';
import * as detergentAPI from '../../core/api/detergents';
import * as reclamationsAPI from '../../core/api/reclamations';
import { fetchOperationPermissionSurveyResults } from '../../core/api/surveys';
import * as taskCompletionsAPI from '../../core/api/task-completions';
import * as washCountsAPI from '../../core/api/washCounts';
import { viewPermissionRefs } from '../../core/components/UserGroupPermissions';
import { LOGOUT_REQUEST } from '../../core/constants';

export const logout = () => {
  return { type: LOGOUT_REQUEST };
};

export const FETCH_MAINTENANCE_TASKS = 'FETCH_MAINTENANCE_TASKS';
export const FETCH_PROJECT_TASKS = 'FETCH_PROJECT_TASKS';
//export const FETCH_ALERTS = 'FETCH_ALERTS';
export const FETCH_ACTIVE_SUBSCRIPTIONS = 'FETCH_ACTIVE_SUBSCRIPTIONS';
export const ACKNOWLEDGE_ALERT = 'ACKNOWLEDGE_ALERT';
export const OVERVIEW_GET_DETERGENTS = 'OVERVIEW_GET_DETERGENTS';
export const OVERVIEW_GET_WASHCOUNTS = 'OVERVIEW_GET_WASHCOUNTS';
export const OVERVIEW_GET_MYCHARTS_WASHCOUNTS = 'OVERVIEW_GET_MYCHARTS_WASHCOUNTS';
export const OVERVIEW_GET_HOURLY_WASHCOUNTS = 'OVERVIEW_GET_HOURLY_WASHCOUNTS';
export const OVERVIEW_GET_CLOSEST_SITES = 'OVERVIEW_GET_CLOSEST_SITES';
export const OVERVIEW_GET_CLOSEST_SITES_HOURLY_WASHCOUNTS =
  'OVERVIEW_GET_CLOSEST_SITES_HOURLY_WASHCOUNTS';
export const OVERVIEW_GET_LATEST_WASHCOUNTS = 'OVERVIEW_GET_LATEST_WASHCOUNTS';
export const FETCH_WINDOW_SALES = 'FETCH_WINDOW_SALES';
export const SET_OVERVIEW_WASH_COUNT_FROM_DATE = 'SET_OVERVIEW_WASH_COUNT_FROM_DATE';
export const SET_OVERVIEW_WASH_COUNT_UNTIL_DATE = 'SET_OVERVIEW_WASH_COUNT_UNTIL_DATE';
export const GET_OVERVIEW_CLAIMCOUNTS = 'GET_OVERVIEW_CLAIMCOUNTS';
export const GET_SURVEY_RESULTS = 'GET_SURVEY_RESULTS';
export const FETCH_REVENUE_SUMMARY = 'FETCH_REVENUE_SUMMARY';
export const FETCH_REVENUE_BEST_DAY_SUMMARY = 'FETCH_REVENUE_BEST_DAY_SUMMARY';
export const FETCH_COST_PER_CAR_DATA = 'FETCH_COST_PER_CAR_DATA';

export const SHOW_OVERVIEW_SUMMARY_CHART = 'SHOW_OVERVIEW_SUMMARY_CHART';
export const SHOW_ALL_ALERTS = 'SHOW_ALL_ALERTS';
export const MINIMIZE_ALERTS = 'MINIMIZE_ALERTS';
export const NOTIFY_ACKNOWLEDGED_ALERT = 'NOTIFY_ACKNOWLEDGED_ALERT';

export const fetchSurveyCancellationResults = (siteId, from, until) => {
  let query = {
    siteId,
    fromDate: from ? from : moment().startOf('day').toISOString(),
    untilDate: until ? until : moment().endOf('day').toISOString(),
  };

  return {
    type: API_REQUEST,
    callAPI: fetchOperationPermissionSurveyResults,
    data: { query },
    action: GET_SURVEY_RESULTS,
    meta: { permissionKey: viewPermissionRefs.survey_chart },
  };
};

export const fetchCostPerCarData = (siteId, from, until, productCategory, washCountCategory) => {
  let query = {
    siteId,
    fromDate: from ?? moment().startOf('day').toISOString(),
    untilDate: until ?? moment().endOf('day').toISOString(),
    productCategory,
    washCountCategory,
  };

  return {
    type: API_REQUEST,
    callAPI: costPerCarAPI.fetchCostPerCarData,
    data: { query },
    action: FETCH_COST_PER_CAR_DATA,
    meta: { permissionKey: viewPermissionRefs.wash_count, useManagerSiteId: true },
  };
};

export const fetchWindowSalesCounts = (siteId, from, until) => {
  let query = {
    siteId,
    from: from ?? moment().startOf('day').toISOString(),
    until: until ?? moment().endOf('day').toISOString(),
  };

  return {
    type: API_REQUEST,
    callAPI: washCountsAPI.fetchDailyWindowSalesGained,
    data: { query },
    action: FETCH_WINDOW_SALES,
    meta: { permissionKey: viewPermissionRefs.wash_count },
  };
};

export const getOverviewWashCounts = (date) => {
  let query = {
    date: date ? date : moment().startOf('day').toISOString(),
  };
  return {
    type: API_REQUEST,
    callAPI: washCountsAPI.fetchOverviewWashCounts,
    action: OVERVIEW_GET_MYCHARTS_WASHCOUNTS,
    data: { query },
    meta: { permissionKey: viewPermissionRefs.wash_count },
  };
};

export const fetchRevenueSummary = (fromDate, untilDate, signal, extId, permittedSites) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
    siteId: extId,
    permittedSites,
  };
  return {
    type: API_REQUEST,
    callAPI: washCountsAPI.fetchRevenueSummary,
    data: { query, signal },
    action: FETCH_REVENUE_SUMMARY,
    meta: {
      permissionKey: viewPermissionRefs.wash_count,
      query,
    },
  };
};
export const fetchRevenueBestDaySummary = (fromDate, untilDate, signal, extId, permittedSites) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
    siteId: extId,
    permittedSites,
  };
  return {
    type: API_REQUEST,
    callAPI: washCountsAPI.fetchRevenueBestDaySummary,
    data: { query, signal },
    action: FETCH_REVENUE_BEST_DAY_SUMMARY,
    meta: {
      permissionKey: viewPermissionRefs.wash_count,
      dateRangeException: true,
      query,
    },
  };
};
export const getWashCounts = (from) => {
  let query = {};
  query = !from ? query : { ...query, from };
  return {
    type: API_REQUEST,
    callAPI: washCountsAPI.fetchWashCounts,
    data: { query },
    action: OVERVIEW_GET_WASHCOUNTS,
    meta: { permissionKey: viewPermissionRefs.wash_count },
  };
};

export const getDetergents = () => ({
  type: API_REQUEST,
  callAPI: detergentAPI.getDetergents,
  action: OVERVIEW_GET_DETERGENTS,
  meta: { permissionKey: viewPermissionRefs.detergent },
});

export const fetchMaintenanceTaskCompletions = () => ({
  type: API_REQUEST,
  callAPI: taskCompletionsAPI.getTaskCompletions,
  data: {
    query: {
      pageSize: 5,
      type: 'Maintenance',
      date: moment().format('YYYY-MM-DD'),
      status: 'Pending',
    },
  },
  meta: { permissionKey: viewPermissionRefs.task },
  action: FETCH_MAINTENANCE_TASKS,
});

export const fetchProjectTaskCompletions = () => ({
  type: API_REQUEST,
  callAPI: taskCompletionsAPI.getTaskCompletions,
  data: {
    query: {
      pageSize: 5,
      type: 'Project',
      date: moment().format('YYYY-MM-DD'),
      status: 'Pending',
    },
  },
  meta: { permissionKey: viewPermissionRefs.task },
  action: FETCH_PROJECT_TASKS,
});

// export const fetchAlerts = (pageSize = 50) => ({
//   type: API_REQUEST,
//   callAPI: alertsAPI.fetchAlerts,
//   data: { query: { pageSize } },
//   action: FETCH_ALERTS,
//   meta: { useManagerSiteId: 'true' },
// });

export const acknowledgeAlert = (id) => ({
  type: API_REQUEST,
  callAPI: alertsAPI.acknowledgeAlert,
  data: { id },
  action: ACKNOWLEDGE_ALERT,
});

export const addTaskCompletion = (taskCompletion, type) => ({
  type: API_REQUEST,
  callAPI: taskCompletionsAPI.postTaskCompletion,
  data: taskCompletion,
  action: ADD_TASK_COMPLETION,
  callback: type === 'Maintenance' ? fetchMaintenanceTaskCompletions : fetchProjectTaskCompletions,
  closeModal: true,
});

export const completeTaskCompletion = (taskCompletion) => ({
  type: API_REQUEST,
  callAPI: taskCompletionsAPI.postTaskCompletionCompleted,
  data: taskCompletion,
  action: COMPLETE_TASK_COMPLETION,
  closeModal: true,
  voidCallback: taskCompletion.voidCallback,
  callback: taskCompletion.callback,
});

export const completeTaskAOT = (task) => ({
  type: API_REQUEST,
  callAPI: taskCompletionsAPI.postTaskCompletionAOT,
  data: task,
  action: COMPLETE_TASK_COMPLETION_AOT,
  closeModal: true,
});

export const snoozeTaskCompletion = (taskCompletion) => ({
  type: API_REQUEST,
  callAPI: taskCompletionsAPI.postTaskCompletionSnoozed,
  data: taskCompletion,
  action: SNOOZE_TASK_COMPLETION,
  closeModal: true,
  voidCallback: taskCompletion.voidCallback,
  callback: taskCompletion.callback,
});

export const getHourlyWashCounts = (fromDate, untilDate, signal) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
  };
  return {
    type: API_REQUEST,
    callAPI: washCountsAPI.fetchHourlyWashCounts,
    action: OVERVIEW_GET_HOURLY_WASHCOUNTS,
    data: { query, signal },
    meta: { permissionKey: viewPermissionRefs.wash_count },
  };
};
export const getClosestsSitesHourlyWashCounts = (fromDate, untilDate, signal) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
  };
  return {
    type: API_REQUEST,
    callAPI: washCountsAPI.fetchHourlyWashCounts,
    action: OVERVIEW_GET_CLOSEST_SITES_HOURLY_WASHCOUNTS,
    data: { query, signal },
    meta: { permissionKey: viewPermissionRefs.wash_count, useClosestSiteIds: true },
  };
};
export const getClosestsSites = (fromDate, untilDate, signal) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
  };
  return {
    type: API_REQUEST,
    callAPI: washCountsAPI.fetchCloseSites,
    action: OVERVIEW_GET_CLOSEST_SITES,
    data: { query, signal },
    meta: { permissionKey: viewPermissionRefs.wash_count },
  };
};
export const getClaimCounts = (fromDate, untilDate) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
  };
  return {
    type: API_REQUEST,
    callAPI: reclamationsAPI.getClaimCounts,
    action: GET_OVERVIEW_CLAIMCOUNTS,
    data: { query },
  };
};

export const showChart = (chart) => ({ type: SHOW_OVERVIEW_SUMMARY_CHART, chart });
export const notifyAcknowledgedAlert = (id) => ({ type: NOTIFY_ACKNOWLEDGED_ALERT, id });
export const setFromDate = (date) => ({ type: SET_OVERVIEW_WASH_COUNT_FROM_DATE, date });
export const setUntilDate = (date) => ({ type: SET_OVERVIEW_WASH_COUNT_UNTIL_DATE, date });

export const fetchActiveSubscriptions = (extId) => ({
  type: API_REQUEST,
  callAPI: washCountsAPI.fetchActiveSubscriptions,
  data: { extId },
  action: FETCH_ACTIVE_SUBSCRIPTIONS,
});
