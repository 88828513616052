import { omit, pick, sortBy, uniqBy } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormSyncErrors, submit, formValueSelector } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import { generateToastr } from '../../../core/toastMessages';
import { fetchProducts } from '../../products/actions';
import { selectProducts } from '../../products/reducer';
import { updateSiteSettings } from '../actions';
import { WashControllerConfigForm } from './WashCounterForm';
import { selectSiteOperations, selectSiteSettings, selectSiteInstallationGroup } from '../reducer';
import { useIntl } from 'react-intl';

export const UpsertWashCounterDialog = (props) => {
  // modal props from showDialog call
  const { formType } = props;

  // subscribe to the store
  const getErrors = getFormSyncErrors('washCounter');
  const formSelector = formValueSelector('washCounter');
  const startAddress = useSelector((state) => formSelector(state, 'startAddress'));
  const addressFields = useSelector((state) => formSelector(state, 'addressFields'));
  const manualAddresses = useSelector((state) => formSelector(state, 'manualAddressses'));
  const manualEntriesPrograms = useSelector((state) =>
    formSelector(state, 'manualEntriesPrograms')
  );
  const siteSettings = useSelector((state) => selectSiteSettings(state));
  const siteInstallationGroup = useSelector((state) => selectSiteInstallationGroup(state));
  const products = useSelector((state) => selectProducts(state));
  const operations = useSelector((state) => selectSiteOperations(state));
  const errors = useSelector((state) => getErrors(state));

  // context
  const intl = useIntl();
  const dispatch = useDispatch();

  const onReady = () => {
    dispatch(submit('washCounter'));
  };

  const checkManualAddresses = (settings) => {
    if (!settings?.[formType]?.manualAddresses) {
      return true;
    }
    const uniqueList = uniqBy(settings[formType].manualAddresses, 'address');
    return uniqueList.length === settings[formType].manualAddresses.length;
  };

  const checkProgramVersionPorts = (ports) => {
    Object.keys(ports).forEach((key) => {
      if (ports[key] === null || ports[key] === '') {
        ports[key] = 1;
      }
    });
  };

  const onSubmit = (values) => {
    const equipmentEntriesSettings = pick(
      values,
      'manualEntriesPrograms',
      'host',
      'port',
      'lcPort',
      'nmPort',
      'vcPort',
      'rocPort'
    );
    const programPorts = pick(equipmentEntriesSettings, 'lcPort', 'nmPort', 'vcPort', 'rocPort');
    checkProgramVersionPorts(programPorts);
    const newSettings = {
      ...siteSettings,
      programVersionGetter: { ...equipmentEntriesSettings, ...programPorts },
    };

    if (values.startAddress && values.addressFields) {
      if (values.startAddress > values.addressFields[0].address) {
        return generateToastr(
          'error',
          'Invalid Start Address',
          'First address must be greater than the starting address field',
          {
            timeOut: 5000,
          }
        );
      }
      // check to make sure addressFields increment by 1
      for (let i = 0; i < values.addressFields.length; i++) {
        let currentAddress = values.addressFields[i].address;
        if (i === values.addressFields.length - 1) {
          break;
        }
        let nextAddress = values.addressFields[i + 1]?.address;
        if (nextAddress !== currentAddress + 1) {
          return generateToastr(
            'error',
            'Invalid Address Fields',
            'Address fields must be sequential',
            {
              timeOut: 5000,
            }
          );
        }
      }
    }

    const combineSettings = {
      ...newSettings,
      [formType]: omit(values, 'manualEntriesPrograms'),
    };

    const settings = omit(combineSettings, ['site', 'modelYearId', 'operationTypeId']);
    const isUniqueList = checkManualAddresses(settings);
    if (isUniqueList) {
      dispatch(updateSiteSettings({ settings }));
    } else {
      return generateToastr('error', 'Duplicate Fields', 'Manual entry addresses must be unique', {
        timeOut: 5000,
      });
    }
  };

  const { isOpen } = props;
  const initialFormValues = {
    ...siteSettings[formType],
    addressFields: sortBy(siteSettings[formType].addresses, 'index'),
    manualAddresses: siteSettings[formType].manualAddresses,
    manualEntriesPrograms: siteSettings.manualEntriesPrograms,
  };

  if (siteSettings.vpnGroupEnabled && siteInstallationGroup) {
    initialFormValues.host = siteInstallationGroup.vpnGateway;
    initialFormValues.port = siteInstallationGroup.modbusPortAllocation.externalPort;
  }

  const submitDisabled = checkErrors(errors);

  const handleHideDialog = () => dispatch(hideDialog());

  return (
    <ModalDialog
      title={`Configure ${intl.formatMessage({ id: formType })}`}
      className="enhanced-dialog large padded"
      onReady={onReady}
      isOpen={isOpen}
      close={handleHideDialog}
      disabled={submitDisabled}
    >
      <WashControllerConfigForm
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        products={products}
        operations={operations}
        startAddress={startAddress}
        addressFields={addressFields}
        manualAddresses={manualAddresses}
        manualEntriesPrograms={manualEntriesPrograms}
        siteSettings={siteSettings}
        siteInstallationGroup={siteInstallationGroup}
        formType={formType}
        enableReinitialize
      />
    </ModalDialog>
  );
};

export default UpsertWashCounterDialog;
